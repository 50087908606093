import { Container } from '@mui/system';
import { useAuth0 } from '@auth0/auth0-react';
import { Card, CardActions, CardContent, Typography } from '@mui/material';
import ResizingButton from '../Components/Shared/ResizingButton';
import { useTranslation } from 'react-i18next';

interface ErrorProps {
	title: string | undefined;
	description: string | undefined;
	displayLoggout: boolean;
}

export default function Exception(props: Readonly<ErrorProps>) {
	const { logout } = useAuth0();
	const { t } = useTranslation();

	return (
		<Container maxWidth="md" sx={{ marginTop: 2 }}>
			<Card>
				<CardContent>
					<Typography variant="h5">{props.title}</Typography>
					<Typography variant="body1">{props.description}</Typography>
				</CardContent>
				{props.displayLoggout && (
					<CardActions>
						<ResizingButton
							onClick={() =>
								logout({
									logoutParams: {
										returnTo: window.location.origin,
									},
								})
							}
							size="small"
						>
							{t('log_out')}
						</ResizingButton>
					</CardActions>
				)}
			</Card>
		</Container>
	);
}
