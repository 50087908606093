import { DeleteOutlined, FileDownloadOutlined } from '@mui/icons-material';
import { Typography, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { useState, useEffect } from 'react';
import { Attachment } from '../Models/Attachment';
import _ from 'lodash';

import {
	ExpenseUnion,
	BaseExpense,
	Flight,
	Representation,
	MileageAllowance,
	OtherTransport,
	ExpenseName,
} from '../Models/Expense';

import {
	putBaseExpense,
	putFlight,
	putRepresentation,
	putMileageAllowance,
	putOtherTransport,
	deleteExpense,
	deleteAttachment,
} from '../Redux/ExpenseFoldersSlice/ExpenseThunks';
import { useAppSelector, useAppDispatch } from '../Redux/Hooks';
import { closeEditExpenseDialog } from '../Redux/Uislice';
import { concatExpenses } from '../Utils/ConcatExpenses';
import EditDialogButtons from './Shared/EditDialogButtons';
import PhotoCapture from './Shared/PhotoCapture';
import { Status } from '../Redux/ExpenseFoldersSlice/ExpenseFoldersSlice';
import ExpenseFormFields from './Shared/ExpenseFormFields';
import AttachmentList from './Shared/AttachmentList';
import { t } from 'i18next';
import AttachmentsBox from './AttachmentsBox';
import { useTranslation } from 'react-i18next';

const EditExpenseFormComponent = () => {
	const expenseInStore = useAppSelector((state) => {
		const expenseInStore = state.expenseFolderReducer.folders
			.flatMap((f) => concatExpenses(f))
			.find((e) => e.id === state.uiReducer.expenseToEditId)!;
		return expenseInStore;
	});

	const expenseFolder = useAppSelector(
		(state) =>
			state.expenseFolderReducer.folders.find((f) =>
				concatExpenses(f).find((e) => e?.id === expenseInStore?.id),
			),
	);

	const isSaving = useAppSelector(
		(state) => state.expenseFolderReducer.expenseStatus,
	);

	const [expense, setExpense] = useState<ExpenseUnion | undefined>(undefined);

	const dispatch = useAppDispatch();

	const t=useTranslation();

	useEffect(() => {
		setExpense({ ...expenseInStore });
	}, [expenseInStore]);

	const handleAttachmentUpload = async (file: File) => {
		fileToBase64(file).then((base64) => {
			const attachment: Attachment = {
				id: 0,
				description: file.name,
				data: base64,
				mediaType: file.type,
				expenseId: expense?.id ?? 0,
			};
			setExpense({
				...expense!,
				attachments: [...(expense!.attachments ?? []), attachment],
			});
		});
	};

	const fileToBase64 = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = (error) => reject(error);
			reader.readAsDataURL(file);
		});
	};

	const putExpense = async (
		e: {
			preventDefault: () => void;
		},
		exp: ExpenseUnion,
	) => {
		e.preventDefault();
		const attachmentsToDelete = expenseInStore.attachments?.filter(
			(a) => !exp.attachments?.map((a) => a.id).includes(a.id),
		);
		attachmentsToDelete?.forEach((a) => dispatch(deleteAttachment(a.id)));
		switch (exp.expenseName) {
			case ExpenseName.Flight:
				dispatch(putFlight(exp as Flight));
				break;

			case ExpenseName.Representation:
				dispatch(putRepresentation(exp as Representation));
				break;

			case ExpenseName.MileageAllowance:
				dispatch(putMileageAllowance(exp as MileageAllowance));
				break;

			case ExpenseName.OtherTransport:
				dispatch(putOtherTransport(exp as OtherTransport));
				break;

			default:
				dispatch(putBaseExpense(exp as BaseExpense));
		}
	};

	if (!expense) return null;

	return (
		<Box>
			<AttachmentsBox expense={expense} setExpense={setExpense} handleAttachmentUpload={handleAttachmentUpload}/>

			<form
				onSubmit={async (e) => {
					await putExpense(e, expense);
					dispatch(closeEditExpenseDialog());
				}}
			>
				<ExpenseFormFields expense={expense} setExpense={setExpense} />

				{expense && (
					<EditDialogButtons
						deleteItem={() => {
							dispatch(deleteExpense(expense.id));
							dispatch(closeEditExpenseDialog());
						}}
						buttonText="delete_expense"
						isSaving={isSaving === Status.Fetching}
						isEditButtonDisabled={_.isEqual(
							expense,
							expenseInStore,
						)}
					/>
				)}
			</form>
		</Box>
	);
};

export default EditExpenseFormComponent;
