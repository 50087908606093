import React from 'react';
import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Image,
} from '@react-pdf/renderer';
import { ExpenseFolder } from '../Models/ExpenseFolder';
import { formatDate } from '../Utils/DateFormatter';
import { concatExpenses } from '../Utils/ConcatExpenses';

import { ExpenseDescription } from '../Utils/ExpenseDescription';
import { ShadedTextBox } from './Pdf/ShadedTextBox';
import { TextBox } from './Pdf/TextBox';
import { BottomText } from './Pdf/BottomText';
import { useTranslation } from 'react-i18next';
import { Profile } from '../Models/Profile';
import {
	formatEndDate,
	formatStartDate,
	shouldDisplayCreditCardFee,
} from './ExpenseFolderComponent';

export interface PDFProps {
	expenseFolder: ExpenseFolder;
	totalAmount: number;
	profile: Profile;
}

// Create styles
export const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		padding: 10,
	},
	section: {
		margin: 5,
		padding: 5,
	},
	title: {
		fontSize: 24,
		textAlign: 'center',
		marginBottom: 20,
	},
	textBox: {
		fontSize: 11,
		fontWeight: 600,
		padding: 5,
		width: '49%',
		marginBottom: 2,
	},
	expensesTable: {
		display: 'flex',
		width: 'auto',
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
		borderWidth: 1,
		borderRightWidth: 0,
		borderBottomWidth: 0,
	},
	tableRow: {
		flexDirection: 'row',
	},
	tableColHeader: {
		flex: 1, // Adjust based on content size
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
		borderBottomColor: '#000',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		paddingBot: 5,
		paddingTop: 5,
	},
	tableCol: {
		flex: 1, // Adjust based on content size
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},

	tableColNoBorderLeftRight: {
		flex: 1, // Adjust based on content size
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderRightWidth: 0,
	},
	tableColNoBorderLeftRightBottom: {
		flex: 2.5, // Adjust based on content size
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderRightWidth: 0,
		alignItems: 'flex-start',
		flexDirection: 'row',
	},
	tableColBot: {
		width: '80%',
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderRightWidth: 0,
		alignItems: 'flex-start',
		flexDirection: 'row',
	},
	tableColNoBorderLeftRightNarrow: {
		flex: 0.35, // Adjust based on content size
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderRightWidth: 0,
	},
	tableColHeaderNarrow: {
		flex: 0.35, // Adjust based on content size, smaller flex value
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
		borderBottomColor: '#000',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		paddingTop: 5,
		paddingBottom: 5,
	},
	tableColNarrow: {
		flex: 0.35, // Adjust based on content size, smaller flex value
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	tableColNoBorderLeftRightMedium: {
		flex: 0.7, // Adjust based on content size
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderRightWidth: 0,
	},
	tableColHeaderMedium: {
		flex: 0.7, // Adjust based on content size, smaller flex value
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
		borderBottomColor: '#000',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		paddingBot: 5,
		paddingTop: 5,
	},
	tableColMedium: {
		flex: 0.7, // Adjust based on content size, smaller flex value
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	tableCellHeader: {
		fontSize: 12,
		fontWeight: 600,
		padding: '5px',
	},
	tableCell: {
		fontSize: 10,
		padding: '5px',
	},
	shadedTextBox: {
		backgroundColor: 'rgba(128, 128, 128, 0.2)',
		fontSize: 11,
		padding: 5,
		width: '49%',
		marginBottom: 5,
	},
	flexRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
});

// Create Document Component
export const PdfDocument: React.FC<PDFProps> = ({
	expenseFolder,
	totalAmount,
	profile,
}) => {
	const { t } = useTranslation();
	return (
		<Document>
			<Page style={styles.page}>
				<View
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'row',
					}}
				>
					<Image src="BhLogo.png" style={{ width: 90, height: 21 }} />
					<View>
						<Text
							style={{
								fontSize: '10',
								color: '#808080',
							}}
						>
							{t('reference_number')}: {expenseFolder.id}
						</Text>
					</View>
				</View>
				<View style={styles.section}>
					<View>
						<Text style={styles.title}>{t('expenses')}</Text>
					</View>
					<TextBox
						textInBoxOne={t('payed_by')}
						textInBoxTwo={t('purpose_with_expense')}
					/>
					<ShadedTextBox
						textInBoxOne={`${profile.firstName} ${profile.lastName}`}
						textInBoxTwo={expenseFolder.description!}
					/>
					<TextBox
						textInBoxOne={t('date')}
						textInBoxTwo={t('account_number')}
					/>
					<ShadedTextBox
						textInBoxOne={
							formatStartDate(expenseFolder) +
							' - ' +
							formatEndDate(expenseFolder)
						}
						textInBoxTwo={profile.bankAccount?.toString() ?? ''}
					/>
				</View>

				<View style={styles.section}>
					<View style={styles.expensesTable}>
						<View style={styles.tableRow}>
							<View style={styles.tableColHeaderNarrow}>
								<Text style={styles.tableCellHeader}>#</Text>
							</View>
							<View style={styles.tableColHeaderMedium}>
								<Text style={styles.tableCellHeader}>
									{t('date')}
								</Text>
							</View>

							<View style={styles.tableColHeader}>
								<Text style={styles.tableCellHeader}>Type</Text>
							</View>

							<View style={styles.tableColHeader}>
								<Text style={styles.tableCellHeader}>
									{t('description')}
								</Text>
							</View>

							<View style={styles.tableColHeaderMedium}>
								<Text style={styles.tableCellHeader}>
									{t('amount')}
								</Text>
							</View>

							<View style={styles.tableColHeaderNarrow}>
								<Text style={styles.tableCellHeader}>
									{t('exchangeRate')}
								</Text>
							</View>

							<View style={styles.tableColHeaderMedium}>
								<Text style={styles.tableCellHeader}>
									{t('amount_in')}{' '}
									{expenseFolder.currencyCode}
								</Text>
							</View>
						</View>

						{concatExpenses(expenseFolder)
							.sort((e, f) => e.id - f.id)
							.map((item, index) => (
								<View style={styles.tableRow} key={item.id}>
									<View style={styles.tableColNarrow}>
										<Text style={styles.tableCell}>
											{index + 1}
										</Text>
									</View>
									<View style={styles.tableColMedium}>
										<Text style={styles.tableCell}>
											{formatDate(item.date)}
										</Text>
									</View>
									<View style={styles.tableCol}>
										<Text style={styles.tableCell}>
											{t(item.expenseName.toString())}
										</Text>
									</View>
									<View style={styles.tableCol}>
										<Text style={styles.tableCell}>
											{ExpenseDescription(
												item,
												t,
												profile,
											)}
										</Text>
									</View>
									<View
										style={{
											...styles.tableColMedium,
											alignItems: 'flex-end',
										}}
									>
										<Text style={styles.tableCell}>
											{formatNumberWithSpaces(
												item.amount,
											) +
												' ' +
												item.currencyCode}
										</Text>
									</View>
									<View
										style={{
											...styles.tableColNarrow,
											alignItems: 'flex-end',
										}}
									>
										<Text style={styles.tableCell}>
											{item.exchangeDetails!.exchangeRate}
										</Text>
									</View>
									<View
										style={{
											...styles.tableColMedium,
											alignItems: 'flex-end',
										}}
									>
										<Text style={styles.tableCell}>
											{formatNumberWithSpaces(
												item.exchangeDetails!
													.amountInTargetCurrency,
											) +
												' ' +
												item.exchangeDetails!
													.targetCurrency}
										</Text>
									</View>
								</View>
							))}
						<View style={styles.tableRow}>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									borderBottomWidth: 1,
									borderRightWidth: 1,
									borderStyle: 'solid',
									borderColor: '#bfbfbf',
									width: '100%',
								}}
							>
								<Text style={styles.tableCell}>
									Total{' '}
									{shouldDisplayCreditCardFee(
										profile,
										expenseFolder,
									)}
								</Text>
								<Text
									style={{
										...styles.tableCell,
										marginLeft: 'auto',
									}}
								>
									{formatNumberWithSpaces(totalAmount) +
										' ' +
										expenseFolder.currencyCode}
								</Text>
							</View>
						</View>
					</View>
				</View>
				<View style={styles.section}>
					<TextBox
						textInBoxOne={t('date')}
						textInBoxTwo={t('date')}
					/>
					<ShadedTextBox
						textInBoxOne=""
						textInBoxTwo={formatDate(Date())}
					/>
					<TextBox
						textInBoxOne={t('authorized_signature')}
						textInBoxTwo={t('signature')}
					/>

					<BottomText
						date={
							formatDate(Date()) +
							'  ' +
							new Date().getHours() +
							':' +
							(new Date().getMinutes() < 10 ? '0' : '') +
							new Date().getMinutes()
						}
						name={`${profile.firstName} ${profile.lastName}`}
					/>
				</View>
			</Page>
		</Document>
	);
};

export const formatNumberWithSpaces = (num: number): string => {
	// Split the number into integer and decimal parts
	const [integerPart, decimalPart] = num.toFixed(2).split('.');

	// Format the integer part with spaces
	const formattedIntegerPart = integerPart.replace(
		/\B(?=(\d{3})+(?!\d))/g,
		' ',
	);

	// Return the formatted number, including the decimal part if it exists
	return decimalPart
		? `${formattedIntegerPart}.${decimalPart}`
		: formattedIntegerPart;
};
