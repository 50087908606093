import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import PhotoCapture from './Shared/PhotoCapture';
import AttachmentList from './Shared/AttachmentList';
import { useAppDispatch, useAppSelector } from '../Redux/Hooks';
import { setBeganExpense } from '../Redux/Uislice';
import { concatExpenses } from '../Utils/ConcatExpenses';
import { t } from 'i18next';
import { ExpenseUnion } from '../Models/Expense';
import { set } from 'lodash';

type AttachmentsBoxProps = {
	handleAttachmentUpload: (attachment: any) => void;
	expense: ExpenseUnion;
	setExpense: (expense: ExpenseUnion) => void;
};

const AttachmentsBox: React.FC<AttachmentsBoxProps> = ({
	handleAttachmentUpload,
	expense,
	setExpense,
}) => {

	const [attachmentCount, setAttachmentCount] = useState<number>();

	const expenseFolder = useAppSelector((state) =>
		state.expenseFolderReducer.folders.find(
			(f) => f.id === state.uiReducer.selectedExpenseFolderId,
		),
	);

	useEffect(() => {
		const attachmentCount =
			expenseFolder &&
			concatExpenses(expenseFolder).flatMap((e) => e.attachments ?? [])
				.length;
		setAttachmentCount(attachmentCount);
	}, [expenseFolder]);

	return (
		<>
			{attachmentCount &&
			attachmentCount + (expense?.attachments?.length ?? 0) > 40 ? (
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
					sx={{
						paddingTop: '1.3rem',
						paddingBottom: '1.3rem',
						border: '2px dashed',
						borderColor: 'rgba(0, 0, 0, 0.12)',
						borderRadius: '5px',
					}}
				>
					{t('max_attachments')}
				</Box>
			) : (
				<PhotoCapture onAttachmentUpload={handleAttachmentUpload} />
			)}

			<AttachmentList
				expense={expense}
				setExpense={setExpense}
			/>
		</>
	);
};

export default AttachmentsBox;
